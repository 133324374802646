<template>
  <div class="login bg-grey-lightest">
    <div class="container">
      <div class="title text-2xl font-bold py-8">
          Welcome to Lorry Route
      </div>
      <div class="login_form bg-white">
        <form @submit.prevent="" class="flex flex-col gap-30">
          <div class="top_line text-2xl">
            <div class="logo">
              <img v-if="logo_url" :src="logo_url" alt="logo">
              <img v-else src="@/assets/images/logo.png" alt="logo">
            </div>
            <div class="title">
              Reset password?
            </div>
          </div>
          <div class="body flex gap-15 flex-col">
            <div class="input_group">
              <input
                  v-model="auth_data.password_digest"
                  type="text"
                  id="username"
                  placeholder="Enter new password"
              />
              <div  v-if="errors.password_digest.length > 0">
                <p class="error">
                  {{ errors.password_digest[0] }}
                </p>
              </div>
            </div>
            <div class="input_group">
              <input
                  v-model="auth_data.password_confirm"
                  type="text"
                  id="password_digest"
                  placeholder="Verify new password"
              />
              <div  v-if="errors.password_confirm.length > 0">
                <p class="error">
                  {{ errors.password_confirm[0] }}
                </p>
              </div>
            </div>
          </div>
          <div class="edit_profile_actions flex-row flex justify-between items-center gap-15">
            <button class="btn w-1/2 green-white"
                    @click="processReset"
            >
              Reset password
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import {infoMessage} from "../../services/messages";
export default {
  name: "ResetPassword",
  data() {
    return {
      logo_url: null,
      auth_data: {
        password_digest: '',
        password_confirm: '',
        password_reset_token: null
      },
      errors: {
        password_digest: [],
        password_confirm: [],
      }
    }
  },
  methods: {
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null
    },
    errorsHandler(errors) {
      const j = this.errors;
      errors.forEach(function(error){
        if (error.split(' ')[0]) {
          j[error.split(' ')[0]].push(error.replaceAll('_', ' '));
        }
      })
    },
    async processReset() {
      try {
        for (let error in this.errors) {
          if (error.length > 0 && error != null) {
            this.errors[error] = []
          }
        }
        const http = this.$http;
        const {password_digest, password_confirm, password_reset_token} = this.auth_data;
        const request_data = {password_digest, password_confirm, password_reset_token};
        let errors = null;
        const response = await http.post(`${http.apiUrl()}/auth/reset-password`, request_data, {Authorization: 'Bearer ' + this.auth_data.password_reset_token}).catch(err => {
          errors = err.response?.data?.message;
          if (typeof errors === 'string') {
            return infoMessage(errors || 'Oops. Something went wrong...', 'error')
          }
          return this.errorsHandler(errors);
        });
        if (errors){
          return
        }
        console.log(response)
        await infoMessage('Password was updated', 'success')
        return await this.$router.push({name: "Login"})
      } catch (e) {
        console.log(e?.response?.data?.error?.message || 'Something went wrong...', 'error');
      }
    },
    renderToken() {
      this.auth_data.password_reset_token = this.$route.query.password_reset_token || null;
    }
  },
  async mounted() {
      await this.logoUrl();
      await this.renderToken();
  }
}
</script>

<style lang="scss" scoped>

</style>

